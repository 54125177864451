/* global google */
import { Controller } from "@hotwired/stimulus";
import styles from "./styles.json";

export default class extends Controller {
  connect() {
    this.loadMapScript();
  }

  loadMapScript() {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_GEOCODING_API_KEY}&callback=initMap`;
    script.async = true;
    script.defer = true;

    window.initMap = this.initMap.bind(this);
    document.body.appendChild(script);
  }

  async initMap() {
    const geocoder = new google.maps.Geocoder();
    this.geocodeAddress(geocoder);
  }

  geocodeAddress(geocoder) {
    const address = this.element.dataset.address;
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === "OK") {
        const map = new google.maps.Map(this.element, {
          zoom: 12,
          center: results[0].geometry.location,
          styles: styles,
        });

        new google.maps.Marker({
          map: map,
          position: results[0].geometry.location,
          // icon: {
          //   url: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png", // URL of your custom image
          //   // scaledSize: new google.maps.Size(50, 50), // size of your icon
          // },
        });
      }
    });
  }
}
