import { partial } from "@lib/fn";

const disableFields = form => {
  form.remove();
};

const enableFields = (container, form) => {
  container.appendChild(form);
};

const isEqual = toggle => toggle.querySelector("input:checked")?.value === "Yes";

const setToggleState = toggle => {
  toggle.dataset.state = isEqual(toggle) ? "eq" : "neq";
};

const toggleMailingAddress = (toggle, container, form) => {
  setToggleState(toggle);

  isEqual(toggle) ? disableFields(form) : enableFields(container, form);
};

const initialiseMailingAddress = (actionName) => {
  const toggle = document.querySelector(`[data-action=${actionName}]`);
  
  if (toggle) {
    const container = toggle.nextElementSibling;
    const form = container.querySelector("[data-component=\"common/addressAutocomplete\"]");
  
    toggle.querySelectorAll("input[type=radio]").forEach(input => {
      input.addEventListener("input", partial(toggleMailingAddress, toggle, container, form));
    });
  
    toggleMailingAddress(toggle, container, form);
  }
}

export default () => {
  initialiseMailingAddress("toggleMailingAddress");
};
