import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["image", "skeleton"];

  connect() {
    if (this.imageTarget.complete) {
      this.handleImageLoaded();
    } else {
      this.imageTarget.addEventListener("load", this.handleImageLoaded.bind(this));
    }
  }

  handleImageLoaded() {
    this.skeletonTarget.classList.add("hidden");
    this.imageTarget.classList.remove("opacity-0");
    this.imageTarget.classList.remove("h-px");
    this.imageTarget.classList.add("opacity-100");
    this.imageTarget.classList.add("h-auto");
  }
}
