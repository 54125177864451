import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  show() {
    var element = document.getElementsByClassName("spinner-content");
    var spinner = document.getElementsByClassName("spinner-loader");

    if (element.length > 0 && spinner.length > 0) {
      spinner[0].classList.add("active");
      element[0].style.display = "none";
    }
  }
}
