import Rails from "@rails/ujs";
import modal from "@lib/modal";
import { partial } from "@lib/fn";

const apply = (_form, _data) => {
  window.location.reload();
};

const notify = (form, data) => {
  const input = form.querySelector(".input");
  const error = document.createElement("span");

  error.classList.add("error");
  error.innerHTML = data.errors.message;

  input.classList.add("field_with_errors");
  input.appendChild(error);
};

const resetErrorState = (form) => {
  const input = form.querySelector(".field_with_errors");

  if (!input) return;

  input.classList.remove("field_with_errors");
  form.querySelector(".error").remove();
};

const submitHandler = (form, event) => {
  event && event.preventDefault();

  const url = form.getAttribute("action");
  const data = form.querySelector("#coupon_discount_code").value;

  resetErrorState(form);

  Rails.ajax({
    url: url,
    type: "POST",
    data: `coupon=${data}`,
    success: partial(apply, form),
    error: partial(notify, form),
  });
};

export default () => {
  const content = document.querySelector('[data-action="discount-modal"]');

  const trigger = document.querySelector('[data-action="show-discount-modal"]');
  trigger.addEventListener("click", modal.show);

  const form = content.querySelector("form.coupon");
  form.addEventListener("submit", partial(submitHandler, form));

  const cancel = content.querySelector("[data-action=dismiss]");
  cancel.addEventListener("click", modal.dismiss);

  modal.load(content);
};
