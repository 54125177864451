import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "acceptedLifeInsuranceCheck",
    "rejectedLifeInsuranceCheck",
    "formAccept",
    "formReject",
    "formContainer"
  ];

  async accept(event) {
    event.preventDefault();
    const formData = new FormData(this.formAcceptTarget);
    const response = await fetch(this.formAcceptTarget.action, {
      method: "POST",
      body: formData,
      headers: { Accept: "application/json" },
    });

    if (response.ok) {
      this.formContainerTarget.classList.add("hidden");
      this.acceptedLifeInsuranceCheckTarget.classList.remove("hidden");
    }
  }

  async reject(event) {
    event.preventDefault();
    const formData = new FormData(this.formRejectTarget);
    const response = await fetch(this.formRejectTarget.action, {
      method: "POST",
      body: formData,
      headers: { Accept: "application/json" },
    });

    if (response.ok) {
      this.formContainerTarget.classList.add("hidden");
      this.rejectedLifeInsuranceCheckTarget.classList.remove("hidden");
    }
  }

  closeModal() {
    location.reload();
  }
}
