import modal from "@lib/modal";

const addEventListeners = (content) => {
  const accept = content.querySelector("[data-action=allow]");
  const cancel = content.querySelector("[data-action=dismiss]")

  if (accept) {
    accept.addEventListener("click", allow);
  }
  cancel.addEventListener("click", deny);
};

const allow = () => {
  const form = document.getElementById("new_pp_crem_contract");

  form.prepend(override());

  form.submit();

  modal.dismiss();
};

const deny = () => {
  modal.dismiss();
};

const override = () => {
  const input = document.createElement("input");

  input.setAttribute("name", "ignore_postcode_mismatch");
  input.setAttribute("type", "hidden");
  input.setAttribute("value", "true");

  return input;
};

export default () => {
  const content = document.querySelector("[data-action=\"postcode-mismatch-modal\"]");

  if (!content) return;

  modal.load(content);

  addEventListeners(content);

  modal.show();
};
