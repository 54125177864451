import displayPostcodeMismatchModal from "./new/postcodeMismatchModal";
import watchMailingAddress from "./new/mailingAddress";
import watchNextOfKin from "./new/nextOfKin";
import email from "@app/components/common/email";
import IMask from "imask";

const dateElementIds = [
  "pp_crem_contract_buyer_attributes_birth_attributes_date",
  "pp_crem_contract_beneficiary_attributes_birth_attributes_date",
];

const maskOptions = {
  mask: Date,
  pattern: 'd/`m/`Y',
  blocks: {
    d: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 31,
      maxLength: 2,
    },
    m: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 12,
      maxLength: 2,
    },
    Y: {
      mask: IMask.MaskedRange,
      from: 1900,
      to: new Date().getFullYear() + 1,
      maxLength: 4
    }
  },
  format: function (date) {
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();

    if (day < 10) day = "0" + day;
    if (month < 10) month = "0" + month;

    return [day, month, year].join('/');
  },
  parse: function (str) {
    var yearMonthDay = str.split('/');
    return new Date(yearMonthDay[2], yearMonthDay[1] - 1, yearMonthDay[0]);
  },
  min: new Date(1900, 0, 1),
  max: new Date(),
  autofix: true,
  lazy: false,
  overwrite: true
};

export default () => {
  displayPostcodeMismatchModal();
  watchMailingAddress();
  watchNextOfKin();
  email();
  document.querySelector(".pp_crem_contract_buyer_title label.radio_buttons").classList.remove("optional");
  document.querySelector(".pp_crem_contract_buyer_title label.radio_buttons").classList.add("required");

  var masks = [];
  dateElementIds.forEach((id) => {
    const element = document.getElementById(id);
    masks.push(IMask(element, maskOptions));
  })
};
