import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const modal = document.querySelector('#modal-stat_dec_locked')
    if (modal) {
      window.addEventListener("load", () => {
        window.HSOverlay.open("#modal-stat_dec_locked");
      });
    }
  }
}