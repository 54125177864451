import { Controller } from "@hotwired/stimulus";
import iconClose from "../../../../assets/images/global/icons/icon-close.svg";

export default class extends Controller {
  static targets = ["articles"];

  connect() {
    const allArticles = document.querySelectorAll(".article-tile-container");

    this.selectedTagsContainer = document.querySelector(".articles-selected-tags");
    this.articlesArray = Array.prototype.slice.call(allArticles);
    this.currentArticleIndex = this._getColumns() * 2;

    this._getOptionsChanged();
    this._initializeArticles(this.articlesArray);
  }

  loadMoreArticles() {
    this.currentArticleIndex += this._getColumns();
    this.articlesArray.forEach((article, index) => {
      if (index >= this.currentArticleIndex) {
        article.style.display = "none";
      } else {
        article.style.display = "flex";
      }
    });
  }

  _getOptionsChanged() {
    document.addEventListener(
      "selectedOptionsChanged",
      ({ detail: { allSelectedOptions, currentOption } }) => {
        this._filterArticles(allSelectedOptions);
        if (currentOption.checked) {
          this._addSelectedTag(allSelectedOptions, currentOption.option);
        } else {
          this._removeSelectedTag(currentOption.option);
        }
      }
    );
  }

  _getColumns() {
    this.articlesContainer = document.querySelector(".articles-body");
    const columnString = getComputedStyle(this.articlesContainer).gridTemplateColumns;
    return columnString.split(" ").length;
  }

  _initializeArticles(articlesArray) {
    articlesArray.forEach((article, index) => {
      if (index >= this.currentArticleIndex) {
        article.style.display = "none";
      }
    });
  }

  // Adds tags as labels to top of the page.
  _addSelectedTag(selectedTags, tag) {
    const tagElement = document.createElement("div");

    tagElement.classList.add("selected-tag", tag);
    tagElement.innerHTML = `<span>${tag.replace(
      /-/g,
      " "
    )}</span><img class="tag-remove-icon" src="${iconClose}"></img>`;

    this.selectedTagsContainer.appendChild(tagElement);
    this._getTagCloseEvent(selectedTags, tag, tagElement);
  }

  _removeSelectedTag(tag) {
    const tagElement = document.querySelector(`.${tag}`);

    this.selectedTagsContainer.removeChild(tagElement);
  }

  // Handles removing of tags through the labels.
  _getTagCloseEvent(selectedTags, tag, tagElement) {
    const tagRemoveIcon = tagElement.querySelector(".tag-remove-icon");

    tagRemoveIcon.addEventListener("click", () => {
      const index = selectedTags.indexOf(tag);

      selectedTags.splice(index, 1);
      this.selectedTagsContainer.removeChild(tagElement);

      // Dispatch event with removed tags that can be listened to by other controllers.
      const removedTagEvent = new CustomEvent("removeTagClicked", {
        detail: {
          tag: tag,
          selectedTags: selectedTags,
        },
      });
      document.dispatchEvent(removedTagEvent);

      this._filterArticles(selectedTags);
    });
  }

  _filterArticles(selectedTags) {
    this._resetArticles();

    this.articlesArray.forEach((article) => {
      const articleTag = article.id;
      if (!selectedTags.includes(articleTag) && selectedTags.length > 0) {
        article.parentNode.removeChild(article);
      }
    });

    const filteredArticles = document.querySelectorAll(".article-tile-container");
    const filteredArticlesArray = Array.prototype.slice.call(filteredArticles);

    this._initializeArticles(filteredArticlesArray);

    if (selectedTags.length > 0) {
      this.selectedTagsContainer.style.display = "flex";
    } else {
      this.selectedTagsContainer.style.display = "none";
    }
  }

  _resetArticles() {
    this.articlesArray.forEach((article) => {
      this.articlesContainer.appendChild(article);
      article.style.display = "flex";
    });
  }
}
