import { Controller } from "@hotwired/stimulus";
import SignaturePad from "signature_pad";

export default class extends Controller {
  static targets = ["canvas", "placeholder"];

  connect() {
    this.initialize();
  }

  show() {
    if (!this.sigPad || this.sigPad.isEmpty()) {
      this.initialize();
    }
  }

  initialize() {
    if (!this.isVisible(this.canvasTarget)) return;

    this.setUpSignaturePad();
    this.resizeCanvas();
    this.addListeners();
  }

  setUpSignaturePad() {
    const brand = this.data.get("brandValue") || "bare";
    const penConfig = {
      minWidth: 1,
      maxWidth: 3,
      penColor: brand === "bare" ? "#035e61" : "#003164",
    };

    if (!this.sigPad) {
      this.sigPad = new SignaturePad(this.canvasTarget, penConfig);
    }
  }

  resizeCanvas() {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    const fixedHeight = 250;

    this.canvasTarget.width = this.canvasTarget.offsetWidth * ratio;
    this.canvasTarget.height = fixedHeight * ratio;
    this.canvasTarget.style.height = `${fixedHeight}px`;

    const context = this.canvasTarget.getContext("2d");
    context.scale(ratio, ratio);

    this.showPlaceholder();
  }

  addListeners() {
    this.canvasTarget.addEventListener("pointerdown", this.hidePlaceholder.bind(this));
    window.addEventListener("resize", this.resizeCanvas.bind(this));
  }

  clearSignature(event) {
    event.preventDefault();
    this.sigPad?.clear();
    this.showPlaceholder();
  }

  hidePlaceholder() {
    this.placeholderTarget.classList.add("hidden");
  }

  showPlaceholder() {
    this.placeholderTarget.classList.remove("hidden");
  }

  getSignatureData() {
    return new Promise((resolve, reject) => {
      if (!this.sigPad.isEmpty()) {
        this.canvasTarget.toBlob((blob) => {
          resolve(blob);
        }, "image/png");
      } else {
        reject(new Error("Signature pad is empty"));
      }
    });
  }

  isVisible(element) {
    return !!element && element.offsetWidth > 0 && element.offsetHeight > 0;
  }
}
