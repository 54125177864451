import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.setupFormVisibility();
    this.setupFormValidation();
  }

  setupFormVisibility() {
    document.querySelector(".admin-approval-accepted-content").style.display = "none";
    document.querySelector(".admin-approval-rejected-content").style.display = "none";

    document.querySelector(".nok-form__select").addEventListener("change", function () {
      const selectedValue = this.value;

      if (selectedValue === "accepted") {
        document.querySelector(".admin-approval-accepted-content").style.display = "block";
        document.querySelector(".admin-approval-rejected-content").style.display = "none";
      } else if (selectedValue === "rejected" || selectedValue === "discard") {
        document.querySelector(".admin-approval-accepted-content").style.display = "none";
        document.querySelector(".admin-approval-rejected-content").style.display = "block";
      } else {
        document.querySelector(".admin-approval-accepted-content").style.display = "none";
        document.querySelector(".admin-approval-rejected-content").style.display = "none";
      }
    });
  }

  setupFormValidation() {
    const submitButton = document.querySelector(".nok-form__submit");
    submitButton.disabled = true;

    const inputs = document.querySelectorAll(
      ".admin-approval-accepted-content input, .admin-approval-accepted-content select, .admin-approval-rejected-content input, .admin-approval-rejected-content select"
    );
    inputs.forEach((input) => {
      input.addEventListener("input", this.checkFormValidity.bind(this));
    });
  }

  checkFormValidity() {
    const selectedValue = document.querySelector(".nok-form__select").value;
    let allInputsValid = false;

    if (selectedValue === "accepted") {
      const textInputs = document.querySelectorAll(
        '.admin-approval-accepted-content input[type="text"], .admin-approval-accepted-content input[type="number"]'
      );
      const selectInputs = document.querySelectorAll(".admin-approval-accepted-content select");
      const checkboxes = document.querySelectorAll(
        '.admin-approval-accepted-content input[type="checkbox"]'
      );

      allInputsValid =
        Array.from(textInputs).every((input) => input.value.trim() !== "") &&
        Array.from(selectInputs).every((select) => select.value && select.value !== "") &&
        Array.from(checkboxes).every((checkbox) => checkbox.checked);
    } else if (selectedValue === "rejected" || selectedValue === "discard") {
      const textInputs = document.querySelectorAll(
        '.admin-approval-rejected-content input[type="text"], .admin-approval-rejected-content input[type="number"]'
      );
      const selectInputs = document.querySelectorAll(".admin-approval-rejected-content select");

      allInputsValid =
        Array.from(textInputs).every((input) => input.value.trim() !== "") &&
        Array.from(selectInputs).every((select) => select.value && select.value !== "");
    }

    document.querySelector(".nok-form__submit").disabled = !allInputsValid;
  }
}
