import collapsible from "@app/components/common/collapsible";
import planSelector from "./show/planSelector";
import discountModal from "./show/discountModal";
import email from "@app/components/common/email";

export default () => {
  collapsible();
  planSelector();
  discountModal();
  email();
};
