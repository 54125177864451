import { partial } from "@lib/fn";

const disable = fields => {
  fields.remove();
};

const enable = (toggle, fields) => {
  toggle.after(fields);
};

const present = toggle => toggle.querySelector("input:checked")?.value === "Yes";

const setToggleState = toggle => {
  toggle.dataset.state = present(toggle) ? "present" : "blank";
};

const toggleFields = (toggle, fields) => {
  setToggleState(toggle);

  present(toggle) ? enable(toggle, fields) : disable(fields);
};

export default () => {
  const toggle = document.querySelector("[data-action=toggleNokFields]");
  const fields = document.querySelector("[data-target=nokFields]");

  toggle.querySelectorAll("input[type=radio]").forEach(input => {
    input.addEventListener("input", partial(toggleFields, toggle, fields));
  });

  toggleFields(toggle, fields);
};
