import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.navElement = this.element.querySelector(".pagy-nav");
    this.prevPageElement = this.element.querySelector(".page.prev");
    this.nextPageElement = this.element.querySelector(".page.next");

    this.replaceTextWithIcon("prev", "&lsaquo;");
    this.replaceTextWithIcon("next", "&rsaquo;");
    this.addFirstAndLastButtons();
    this.addStyleClasses();
  }

  replaceTextWithIcon(className, icon) {
    const element = this.element.querySelector(`.page.${className} a`);
    const disabledElement = this.element.querySelector(`.page.${className}.disabled`);

    if (element) element.innerHTML = icon;
    if (disabledElement) disabledElement.innerHTML = `<div>${icon}</div>`;
  }

  addFirstAndLastButtons() {
    const firstPageLink = this.prevPageElement.nextElementSibling.querySelector("a");
    const lastPageLink = this.nextPageElement.previousElementSibling.querySelector("a");

    const firstPageHref = firstPageLink ? firstPageLink.href : "#";
    const lastPageHref = lastPageLink ? lastPageLink.href : "#";

    const isPrevDisabled = this.prevPageElement.classList.contains("disabled");
    const isNextDisabled = this.nextPageElement.classList.contains("disabled");

    const firstButton = this.createPageButton("first", "&laquo;", firstPageHref, isPrevDisabled);
    const lastButton = this.createPageButton("last", "&raquo;", lastPageHref, isNextDisabled);

    this.navElement.insertBefore(firstButton, this.navElement.firstChild);
    this.navElement.appendChild(lastButton);
  }

  createPageButton(className, icon, href, isDisabled) {
    const button = document.createElement("span");
    button.className = `page ${className} ${isDisabled ? "disabled" : ""}`;
    button.innerHTML = isDisabled
      ? `<div>${icon}</div>`
      : `<a href="${href}" data-turbo-action="advance">${icon}</a>`;
    return button;
  }

  addStyleClasses() {
    this.navElement.classList.add("flex", "gap-1");

    this.element.querySelectorAll(".page").forEach((page) => {
      page.classList.add(
        "flex",
        "items-center",
        "justify-center",
        "cursor-pointer",
        "font-bold",
        "text-xs",
        "p-4",
        "h-8",
        "w-8"
      );
    });

    this.element.querySelectorAll(".active").forEach((link) => {
      link.classList.add("rounded-full", "border", "border-mint-300");
    });

    this.element.querySelectorAll(".disabled").forEach((link) => {
      link.classList.add("text-gray-400", "cursor-default");
      link.classList.remove("cursor-pointer");
    });
  }

  reloadPartial(event) {
    event.preventDefault();
    const url = new URL(event.currentTarget.href);
    const partialName = url.searchParams.get("partial");

    const customEvent = new CustomEvent("partial_navigation:loadPartial", {
      detail: { partialName: partialName },
    });

    history.pushState(null, "", url);
    document.dispatchEvent(customEvent);
  }
}
