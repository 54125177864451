import sendSentryError from "./sendSentryError";

export default (_options) => {
  var el = document.createElement("div");
  el.style = "margin: 0 auto;";
  el.innerHTML =
    '<div class="spinner-loader"><div class="spinner" style="border-color: #035e61; border-top-color: #dee2e6;"></div></div>';
  document.querySelector(".sd-completedpage").append(el);

  var url = document.getElementById("surveyContainer").getAttribute("data-check-url");
  var completeRedirect = document
    .getElementById("surveyContainer")
    .getAttribute("data-complete-url");

  const request = new XMLHttpRequest();
  let attempts = 0;

  const executePoll = async (resolve, reject) => {
    request.open("POST", url);
    request.setRequestHeader(
      "X-CSRF-Token",
      document.querySelector("meta[name=csrf-token]")?.content
    );
    request.send();
    attempts++;

    request.onreadystatechange = () => {
      // readyState === 4 means operation is complete as per: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/readyState
      if (request.readyState === 4) {
        if (request.status === 200 && JSON.parse(request.response).eulogy?.length > 0) {
          window.location.href = completeRedirect;
        } else if (attempts >= 6) {
          var errorEl = document.createElement("p");
          errorEl.innerHTML =
            "Something went wrong while fetching your eulogy. Please contact support";
          document.querySelector(".sd-completedpage").append(errorEl);

          // Double, but we want to leave the old way for now.
          sendSentryError("Exceeded max (6) attempts in getting eulogy response.");
          throw getError("Exceeded max (6) attempts in getting eulogy response.");
        } else {
          setTimeout(executePoll, 10000, resolve, reject);
        }
      }
    };
  };

  const getError = (error) => {
    let newError = new Error(error);
    newError.name = `EulogyWriter: ${error}`;

    return newError;
  };

  executePoll();
};
