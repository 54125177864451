import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const allFilterItems = document.querySelectorAll(".filter-item");

    this.filterItemsArray = Array.prototype.slice.call(allFilterItems);
    this.currentItemIndex = this._getColumns() * 2;

    this._getOptionsChanged();
    this._initializeItems(this.filterItemsArray);
  }

  loadMoreItems() {
    this.currentItemIndex += this._getColumns();
    this.filterItemsArray.forEach((item, index) => {
      if (index >= this.currentItemIndex) {
        item.style.display = "none";
      } else {
        item.style.display = "flex";
      }
    });
  }

  _getOptionsChanged() {
    document.addEventListener(
      "selectedOptionsChanged",
      ({ detail: { allSelectedOptions, currentOption } }) => {
        this._filterArticles(allSelectedOptions);
        if (currentOption.checked) {
          this._addSelectedTag(allSelectedOptions, currentOption.option);
        } else {
          this._removeSelectedTag(currentOption.option);
        }
      }
    );
  }

  _getColumns() {
    this.articlesContainer = document.querySelector(".articles-body");
    const columnString = getComputedStyle(this.articlesContainer).gridTemplateColumns;
    return columnString.split(" ").length;
  }

  _initializeItems(itemArray) {
    itemArray.forEach((item, index) => {
      if (index >= this.currentItemIndex) {
        item.style.display = "none";
      }
    });
  }

  // Handles removing of tags through the labels.
  _getTagCloseEvent(selectedTags, tag, tagElement) {
    const tagRemoveIcon = tagElement.querySelector(".tag-remove-icon");

    tagRemoveIcon.addEventListener("click", () => {
      const index = selectedTags.indexOf(tag);

      selectedTags.splice(index, 1);

      // Dispatch event with removed tags that can be listened to by other controllers.
      const removedTagEvent = new CustomEvent("removeTagClicked", {
        detail: {
          tag: tag,
          selectedTags: selectedTags,
        },
      });
      document.dispatchEvent(removedTagEvent);

      this._filterArticles(selectedTags);
    });
  }

  _filterArticles(selectedTags) {
    this._resetFilterItems();

    this.filterItemsArray.forEach((article) => {
      const articleTag = article.id;
      if (!selectedTags.includes(articleTag) && selectedTags.length > 0) {
        article.parentNode.removeChild(article);
      }
    });

    const filteredItems = document.querySelectorAll(".filter-item");
    const filteredItemsArray = Array.prototype.slice.call(filteredItems);

    this._initializeItems(filteredItemsArray);
  }

  _resetFilterItems() {
    this.filterItemsArray.forEach((article) => {
      this.articlesContainer.appendChild(article);
      article.style.display = "flex";
    });
  }
}
