import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggle", "drawer"];

  connect() {
    super.connect();
  }

  toggleDrawer() {
    this.toggleTarget.classList.toggle("open");
    this.drawerTarget.classList.toggle("show");
  }

  toggleCollapsible(event) {
    const id = event.currentTarget.dataset.value;
    var el = document.querySelector("#" + id);
    el.classList.toggle("open");
  }
}
