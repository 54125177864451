import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  check(event) {
    const labelId = event.currentTarget.getAttribute("data-radio-with-price-id-value");
    const radioWithPriceWrappers = document.querySelectorAll(".radio-with-price-wrapper");

    radioWithPriceWrappers.forEach((wrapper) => {
      if (wrapper.id === labelId) {
        wrapper.querySelector("label").classList.remove("checked");
      }
    });

    event.currentTarget.classList.add("checked");
    this.inputTarget.checked = true;
  }
}
