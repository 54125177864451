import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content", "link", "leftShadow", "rightShadow"];

  connect() {
    this.sections = Array.from(this.contentTarget.children);
    this.activeSection = null;

    this.updateActiveLink();

    this.element.addEventListener("scroll", this.updateActiveLink.bind(this));
    this.linkTargets.forEach((link) => link.addEventListener("click", this.scrollTo.bind(this)));
  }

  updateActiveLink() {
    const scrollLeft = this.element.scrollLeft;
    const maxScrollLeft = this.element.scrollWidth - this.element.clientWidth;

    const threshold = 1;

    const isAtLeft = scrollLeft <= threshold;
    const isAtRight = Math.abs(scrollLeft - maxScrollLeft) <= threshold;

    this.toggleLinkActivation("left", !isAtLeft);
    this.toggleLinkActivation("right", !isAtRight);

    this.leftShadowTarget.classList.toggle("opacity-0", isAtLeft);
    this.rightShadowTarget.classList.toggle("opacity-0", isAtRight);
  }

  toggleLinkActivation(side, isActive) {
    this.linkTargets.forEach((link) => {
      const href = link.getAttribute("href");
      const isMatch = href.endsWith(`#${side}`);
      if (isMatch) {
        link.parentElement.classList.toggle("active", isActive);
      }
    });
  }

  scrollTo(event) {
    event.preventDefault();

    const link = event.currentTarget;
    const targetId = link.getAttribute("href").replace("#", "");
    const maxScrollLeft = this.element.scrollWidth - this.element.clientWidth;
    const leftOffset = targetId === "left" ? 0 : maxScrollLeft;

    this.element.scrollTo({
      left: leftOffset,
      behavior: "smooth",
    });
  }
}
