import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["navigation", "content"];
  static values = {
    brand: String,
  };

  connect() {
    const brand = this.brandValue ? this.brandValue : "bare";
    this.setCurrentNavDot(brand);

    document.addEventListener("scrollElementIntoView", (event) => {
      this.scrollElementIntoView(event.detail);
    });
  }

  scrollElementIntoView(index) {
    this.contentTarget.children[index].scrollIntoView({ behavior: "smooth" });
  }

  scrollLeft() {
    this.contentTarget.scrollBy({ left: -1 * this.getScroll(), top: 0, behavior: "smooth" });
  }

  scrollRight() {
    this.contentTarget.scrollBy({ left: this.getScroll(), top: 0, behavior: "smooth" });
  }

  getScroll() {
    const videoCard = this.contentTarget.querySelector(":first-child");
    const gap = parseInt(getComputedStyle(this.contentTarget).gap, 10);
    const { width } = videoCard.getBoundingClientRect();
    return width + gap;
  }

  setCurrentNavDot(brand) {
    const colorPrimary = brand === "bare" ? "#035E61" : "#A6CDE7";
    const colorSecondary = brand === "bare" ? "#B0E0d6" : "#003164";

    const navDots = this.navigationTarget.querySelectorAll(".nav-dot");
    const itemLength = navDots.length;
    let currentNavDot = 0;
    navDots[currentNavDot].firstElementChild.style.fill = colorPrimary; // Set initial color
    this.contentTarget.onscroll = function (event) {
      let currentScroll = event.target.scrollLeft;
      const maxScrollLeft = event.target.scrollWidth - event.target.clientWidth;
      const navDotIncrement = maxScrollLeft / (itemLength - 1);
      currentNavDot = Math.round(currentScroll / navDotIncrement);
      navDots.forEach((navDot) => {
        navDot.firstElementChild.style.fill = colorSecondary; // Set secondary color
      });
      if (navDots[currentNavDot]) {
        navDots[currentNavDot].firstElementChild.style.fill = colorPrimary; // Set primary color for current nav dot
      }
    };
  }
}
