import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";

const iconTrash = `<svg width="20" height="15" viewBox="6 5 20 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.75 10.5H21.25V23C21.25 24.3807 20.1307 25.5 18.75 25.5H11.25C9.86929 25.5 8.75 24.3807 8.75 23V10.5Z" stroke="currentColor" stroke-width="1.875"/><path d="M11.25 9.25C11.25 7.86929 12.3693 6.75 13.75 6.75H16.25C17.6307 6.75 18.75 7.86929 18.75 9.25V10.5H11.25V9.25Z" stroke="currentColor" stroke-width="1.875"/><path d="M6.25 10.5H23.75" stroke="currentColor" stroke-width="1.875" stroke-linecap="round"/></svg>`;
const iconHeart = `<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.73707 0.817149C8.17605 0.75503 7.60923 0.861271 7.10879 1.12234C6.60834 1.38342 6.19691 1.78751 5.92686 2.28318C5.65556 1.78573 5.24204 1.38052 4.73919 1.11937C4.23635 0.858211 3.66703 0.752986 3.10406 0.817149C2.74636 0.871106 2.40364 0.998289 2.09733 1.19074C1.79103 1.38319 1.52772 1.63678 1.32389 1.93563C1.12006 2.23449 0.980077 2.57219 0.912705 2.92761C0.845334 3.28303 0.85202 3.64853 0.932345 4.00125C1.35241 6.01757 3.63333 6.94171 5.91846 9.20167C8.19939 6.92071 10.4845 6.01757 10.9004 4.00125C10.9807 3.64914 10.9877 3.28425 10.9209 2.92932C10.854 2.5744 10.7148 2.23705 10.5118 1.93831C10.3088 1.63958 10.0465 1.38585 9.74115 1.19296C9.43582 1.00006 9.09402 0.872134 8.73707 0.817149V0.817149Z" stroke="currentColor" stroke-width="0.980959" stroke-linecap="round" stroke-linejoin="round"/></svg>`;

// let debounce = require("lodash/debounce");
const BREAKPOINT_TABLET = 992;

export default class extends Controller {
  static targets = [
    "venueContainer",
    "venueAddButton",
    "venueAddButtonWrapper",
    "venueDetailButtonHeader",
    "venueDetailButtonFooter",
  ];

  initialize() {
    this.submit = debounce(this.submit.bind(this), 300);
  }

  connect() {
    this.isInShortlist = false;

    document.addEventListener("removedFromShortlist", (event) => {
      if (
        (this.hasVenueContainerTarget && this.venueContainerTarget.dataset.id === event.detail) ||
        (this.hasVenueDetailButtonHeaderTarget &&
          this.venueDetailButtonHeaderTarget.dataset.id === event.detail) ||
        (this.hasVenueDetailButtonFooterTarget &&
          this.venueDetailButtonFooterTarget.dataset.id === event.detail)
      ) {
        this.toggleShortlist();
      }
    });

    document.addEventListener("addedToShortlist", (event) => {
      if (
        (this.hasVenueContainerTarget && this.venueContainerTarget.dataset.id === event.detail) ||
        (this.hasVenueDetailButtonHeaderTarget &&
          this.venueDetailButtonHeaderTarget.dataset.id === event.detail) ||
        (this.hasVenueDetailButtonFooterTarget &&
          this.venueDetailButtonFooterTarget.dataset.id === event.detail)
      ) {
        this.toggleShortlist();
      }
    });

    document.addEventListener("shortlistFull", () => {
      this.shortlistFull = true;
      this.checkButtonStatus();
    });

    document.addEventListener("shortlistNotFull", () => {
      this.shortlistFull = false;
      this.checkButtonStatus();
    });
  }

  submit() {
    this.element.requestSubmit();
  }

  addToShortlist(event) {
    const itemData = {
      id: event.currentTarget.dataset.id,
      url: event.currentTarget.dataset.url,
      image: event.currentTarget.dataset.itemImage,
      title: event.currentTarget.dataset.itemTitle,
      suburb: event.currentTarget.dataset.itemSuburb,
      address: event.currentTarget.dataset.itemAddress,
      postcode: event.currentTarget.dataset.itemPostcode,
      state: event.currentTarget.dataset.itemState,
      type: event.currentTarget.dataset.itemType,
    };
    document.dispatchEvent(
      new CustomEvent("addToShortlist", {
        detail: itemData,
      })
    );

    // Send analytics event.
    window.sendSegmentAnalytics("Nok", "Memorial Venue Shortlist", "added", {
      venue_name: itemData.title,
      venue_type: itemData.type,
    });
  }

  removeFromShortlist(event) {
    const itemData = {
      id: event.currentTarget.dataset.id,
      title: event.currentTarget.dataset.itemTitle,
      type: event.currentTarget.dataset.itemType,
    };
    document.dispatchEvent(
      new CustomEvent("removeFromShortlist", {
        detail: {
          id: itemData.id,
        },
      })
    );

    // Send analytics event.
    window.sendSegmentAnalytics("Nok", "Memorial Venue Shortlist", "removed", {
      venue_name: itemData.title,
      venue_type: itemData.type,
    });
  }

  toggleShortlist(event = null) {
    this.isInShortlist = !this.isInShortlist;
    if (this.isInShortlist) {
      if (event) {
        this.addToShortlist(event);
      }
      if (this.hasVenueContainerTarget) {
        this.venueContainerTarget.classList.add("active");
        this.venueAddButtonTarget.className = "button--primary  button--small";
        this.venueAddButtonTarget.innerHTML = `<span class="venue-toggle-button">${iconTrash} Remove</span>`;

        // Open to the shortlist element for mobile and tablet views
        if (window.innerWidth <= BREAKPOINT_TABLET) {
          const shortlistCollapsable = document.querySelector(".shortlist .bfg-collapse");
          if (shortlistCollapsable && !shortlistCollapsable.classList.contains("active")) {
            shortlistCollapsable.click();
          }
        }
      }
      if (this.hasVenueDetailButtonHeaderTarget || this.hasVenueDetailButtonFooterTarget) {
        this.venueDetailButtonHeaderTarget.innerHTML = `<span class="venue-toggle-button">${iconTrash} Remove</span>`;
        this.venueDetailButtonFooterTarget.innerHTML = `<span class="venue-toggle-button">${iconTrash} Remove</span>`;
      }
    } else {
      if (event) {
        this.removeFromShortlist(event);
      }
      if (this.hasVenueContainerTarget) {
        this.venueContainerTarget.classList.remove("active");
        this.venueAddButtonTarget.className = "button--secondary  button--small";
        this.venueAddButtonTarget.innerHTML = `<span class="venue-toggle-button">${iconHeart} Shortlist</span>`;
      }
      if (this.hasVenueDetailButtonHeaderTarget || this.hasVenueDetailButtonFooterTarget) {
        this.venueDetailButtonHeaderTarget.innerHTML = `<span class="venue-toggle-button">${iconHeart} Shortlist</span>`;
        this.venueDetailButtonFooterTarget.innerHTML = `<span class="venue-toggle-button">${iconHeart} Shortlist</span>`;
      }
    }
    this.checkButtonStatus();
  }

  createTooltip() {
    let tooltipSpan = document.createElement("span");
    tooltipSpan.classList.add("venue-button-tooltip");
    tooltipSpan.textContent = "Already 3 on shortlist.";

    this.venueAddButtonTarget.appendChild(tooltipSpan);

    this.venueAddButtonWrapperTarget.addEventListener("mouseover", function () {
      if (tooltipSpan) tooltipSpan.classList.add("hovered");
    });

    this.venueAddButtonWrapperTarget.addEventListener("mouseout", function () {
      if (tooltipSpan) tooltipSpan.classList.remove("hovered");
    });
  }

  checkButtonStatus() {
    if (this.hasVenueAddButtonTarget) {
      if (this.isInShortlist || !this.shortlistFull) {
        this.venueAddButtonTarget.disabled = false;
      } else {
        this.venueAddButtonTarget.disabled = true;
        this.createTooltip();
      }
    }
    if (this.hasVenueDetailButtonHeaderTarget) {
      if (this.shortlistFull && !this.isInShortlist) {
        this.venueDetailButtonHeaderTarget.disabled = true;
        this.venueDetailButtonFooterTarget.disabled = true;
      } else {
        this.venueDetailButtonHeaderTarget.disabled = false;
        this.venueDetailButtonFooterTarget.disabled = false;
      }
    }
  }
}
