import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["toggle", "menu"];

    connect() {
        super.connect()
        document.addEventListener('click', (event) => {
            const isWithinComponent = !!event.target.closest('.profile_container');

            if (!isWithinComponent) {
                // Closes menu on off click.
                this.menuTarget.classList.add('menu-hide');
            }
        });
    }

    toggleMenu() {
        this.menuTarget.classList.toggle('menu-hide');
    }
}
