import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["guestQuestion", "form", "thankYouMessage", "formContainer"];

  connect() {
    const closeButton = document.getElementById("condolence-close-button");
    closeButton.addEventListener("click", this.debounce(this.reloadPartial.bind(this), 10));
  }

  async submit(event) {
    event.preventDefault();

    this.validateFields();
    if (!this.formTarget.checkValidity()) {
      this.formTarget.reportValidity();
      return;
    }

    const formData = new FormData(this.formTarget);
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content");
    const response = await fetch(this.formTarget.action, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
      },
      body: formData,
    });

    if (response.ok) {
      this.formContainerTarget.classList.add("hidden");
      this.thankYouMessageTarget.classList.remove("hidden");
    }
  }

  addAnotherCondolence() {
    this.formContainerTarget.classList.remove("hidden");
    this.thankYouMessageTarget.classList.add("hidden");

    const inputs = this.formTarget.querySelectorAll("textarea");
    inputs.forEach((input) => {
      input.value = "";
    });
  }

  validateFields() {
    const inputs = this.formTarget.querySelectorAll("input[required]");
    inputs.forEach((input) => {
      if (input.validity.patternMismatch) {
        input.setCustomValidity(
          "Please use normal characters (letters, apostrophe's and hyphens only)."
        );
      } else if (input.value.length > 50) {
        input.setCustomValidity("This field cannot exceed 50 characters.");
      } else {
        input.setCustomValidity("");
      }
    });
  }

  reloadPartial(event) {
    event.preventDefault();

    this.disableButtonsInPartial(document.querySelector("#partial-navigation-content"));

    const url = new URL(window.location.href);
    const partialName = url.searchParams.get("partial");

    const customEvent = new CustomEvent("partial_navigation:loadPartial", {
      detail: { partialName: partialName },
    });
    document.dispatchEvent(customEvent);
  }

  debounce(func, wait) {
    return function (...args) {
      clearTimeout(this.constructor.debounceTimeout);
      this.constructor.debounceTimeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  disableButtonsInPartial(partial) {
    const buttons = partial.querySelectorAll("button");
    buttons.forEach((button) => {
      button.disabled = true;
    });
  }
}
