import collapsible from "@app/components/common/collapsible";
import collapsibleContainer from "@app/components/common/collapsibleContainer";

export default() => {
  collapsible();
  collapsibleContainer();

  if (screen.width >= 1280) {
    var collapse = document.getElementsByClassName('collapse');

    for (var i = 0; i < collapse.length; i++) {
      collapse[i].click();
    }
  }
}