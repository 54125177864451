import saveSurveyData from "@app/controllers/bare_forms/form_functions/saveSurveyData";
import getEulogy from "@app/controllers/bare_forms/form_functions/getEulogy";

export default (survey) => {
  var state = document.getElementById("surveyContainer").getAttribute("data-response-state");

  let button;
  let buttonText;
  let url;

  if (state === "NSW" || state === "ACT") {
    button = document.createElement("a");
    url = window.location.href.replace("form", "statutory_declaration/start");
    buttonText = "Go to Statutory Declaration";
  } else {
    button = document.createElement("a");
    url = window.location.href.replace(/form|preconsultation_form/, "home");
    buttonText = "Return to Home";
  }

  button.className = "button--primary home-button";
  button.innerText = buttonText;
  button.href = url;
  const pageBody = document.querySelector(".sd-body");
  if (pageBody) {
    const buttonLocation = pageBody.lastChild;
    buttonLocation.parentNode.insertBefore(button, buttonLocation.nextSibling);
  }
  saveSurveyData(survey, true, false);

  var completeRedirect = document
    .getElementById("surveyContainer")
    .getAttribute("data-complete-url");
  if (completeRedirect && document.querySelector(".eulogies")) {
    getEulogy();
  }
};
