import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "hiddenInput"];

  connect() {
    const urlParams = new URLSearchParams(window.location.search);
    const openModal = urlParams.get("open_condolence_modal");

    console.log("init");
    if (openModal === "true") {
      this.initializeModal();
    }
  }

  async initializeModal() {
    await this.waitForHSOverlay();

    const modalInstance = window.HSOverlay.getInstance("#modal-condolence");

    if (modalInstance) {
      window.HSOverlay.open("#modal-condolence");

      const url = new URL(window.location.href);
      url.searchParams.delete("open_condolence_modal");
      window.history.replaceState({}, document.title, url);
    }
  }

  waitForHSOverlay() {
    const maxAttempts = 20;

    return new Promise((resolve, reject) => {
      let attempts = 0;
      const interval = setInterval(() => {
        if (window.HSOverlay && window.HSOverlay.getInstance) {
          clearInterval(interval);
          resolve();
        } else if (attempts >= maxAttempts) {
          clearInterval(interval);
          reject("HSOverlay failed to load.");
        }
        attempts++;
      }, 2000);
    });
  }

  async submit(event) {
    event.preventDefault();

    this.hiddenInputTarget.value = event.target.innerText == "Hide" ? "true" : "false";
    const formData = new FormData(this.formTarget);
    formData.append("_method", "PATCH");
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content");
    const response = await fetch(this.formTarget.action, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
      },
      body: formData,
    });

    if (response.ok) {
      this.element.classList.toggle("message-hidden");
    }
  }
}
