import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select", "error"];

  connect() {
    if (this.hasSelectTarget) {
      this.selectTarget.addEventListener("change", this.toggleError.bind(this));
    }
  }

  toggleError() {
    if (this.selectTarget.value) {
      this.errorTarget.style.display = "none";
    } else {
      this.errorTarget.style.display = "block";
    }
  }
}
