import { Controller } from "@hotwired/stimulus";
import collapsibleContainer from "@app/components/common/collapsibleContainer";

export default class extends Controller {
  static targets = ["prepaidTablinks", "prepaidTabcontent"];
  connect() {
    collapsibleContainer();
    this._initializeMailingAddress();
  }

  setTabContent(event) {
    this._hideAndsetContent(event.currentTarget.id);
  }

  toggleMailingAddress(event) {
    var element = document.getElementById(
      "toggleMailingAddress-" + event.currentTarget.id
    );
    const container = element.nextElementSibling;
    const collapse = element.parentElement.parentElement;

    if (event.currentTarget.value === "Same address as above") {
      container.style.display = "none";
    } else {
      container.style.display = "block";
    }

    collapse.style.maxHeight = collapse.scrollHeight + "px";
  }

  deleteNextOfKin(event) {
    //getting the form to hide
    var element = document.getElementById("nok-form-" + event.currentTarget.id);
    //setting the hidden field of form "delete_next_of_kin" to true to get it in rails app
    element.children[0].value = true;
    //setting the required inputs of nok form to false (To handle an edge case in which admin tries to delete the form with all nill values)
    var inputs = document.getElementsByClassName(
      "nok-form-input-" + event.currentTarget.id
    );
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].required = false;
    }
    //hidding the form
    element.style.display = "none";

    var container = document.querySelector(".tabcontent.active");
    container.querySelector(".add-nok-btn").style.display = "flex";
    event.currentTarget.style.display = "none";
  }

  addNextOfKin(event) {
    var id = event.currentTarget.getAttribute("data-contract-id");
    //getting the form to hide
    var element = document.getElementById("nok-form-" + id);
    //setting the hidden field of form "delete_next_of_kin" to true to get it in rails app
    element.querySelector("#pp_crem_contract_delete_next_of_kin").value = false;
    //setting the required inputs of nok form to false (To handle an edge case in which admin tries to delete the form with all nill values)
    var inputs = document.getElementsByClassName("nok-form-input-" + id);
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].required = true;
    }
    //hidding the form
    element.style.display = "block";

    var content = document.querySelector(
      ".tabcontent.active > .collapse-container > .collapse-body.opened"
    );
    content.style.maxHeight = content.scrollHeight + "px";

    event.currentTarget.style.display = "none";
    var container = document.querySelector(".tabcontent.active");
    container.querySelector(".delete-nok-btn").style.display = "flex";
  }

  _hideAndsetContent(id) {
    var tabcontent = this.prepaidTabcontentTargets;

    this.prepaidTablinksTargets.forEach((element, index) => {
      element.classList.remove("active");
      tabcontent[index].classList.remove("active");
      if (element.id == id) {
        element.classList.add("active");
        tabcontent[index].classList.add("active");
      }
    });
  }

  _initializeMailingAddress() {
    const toggle = document.getElementsByClassName("toggleMailingAddress");

    for (let i = 0; i < toggle.length; i++) {
      const container = toggle[i].nextElementSibling;
      if (
        toggle[i].querySelector("input:checked")?.value ===
        "Same address as above"
      ) {
        container.style.display = "none";
      } else {
        container.style.display = "block";
      }
    }
  }
}
