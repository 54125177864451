import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submitButton"];

  connect() {
    const inputField = document.querySelector(".share-quote-url-input-field");
    if (inputField) {
      this.addListeners();
    }

    if (this.hasSubmitButtonTarget) {
      const serviceRadios = document.querySelectorAll(
        'input[name="form_object_deals_dashboard_quote[service_type]"]'
      );
      const paymentRadios = document.querySelectorAll(
        'input[name="form_object_deals_dashboard_quote[payment_method]"]'
      );

      serviceRadios.forEach((radio) =>
        radio.addEventListener("click", () => this.updateSubmitButtonState())
      );
      paymentRadios.forEach((radio) =>
        radio.addEventListener("click", () => this.updateSubmitButtonState())
      );
    }
  }

  addListeners() {
    const inputField = document.querySelector(".share-quote-url-input-field");
    const linkValue = inputField.value;
    let tooltip = document.querySelector(".tooltip");

    inputField.addEventListener("focus", () => {
      inputField.classList.add("copied");

      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard
          .writeText(linkValue)
          .then()
          .catch(() => {
            this.showToolTip(tooltip, inputField, "Failed to write to clipboard");
          });
      } else {
        this.showToolTip(tooltip, inputField, "Failed to write to clipboard");
      }
    });

    inputField.addEventListener("blur", () => {
      inputField.classList.remove("copied");
    });
  }

  copyToClipboard(_event) {
    const inputField = document.querySelector(".share-quote-url-input-field");
    let tooltip = document.querySelector(".tooltip");

    this.showToolTip(tooltip, inputField, "copied!");
    inputField.focus();
  }

  showToolTip(tooltip, input, text) {
    if (!tooltip) {
      console.log(input);
      tooltip = document.createElement("span");
      tooltip.classList.add("tooltip");
      input.parentNode.insertBefore(tooltip, input.nextSibling);
    }

    tooltip.innerText = text;
    tooltip.classList.add("show");
    tooltip.style.animation = "";

    setTimeout(() => {
      tooltip.style.animation = "fadeout 1s forwards";
    }, 1000);
  }

  returnToQuotePage(event) {
    event.preventDefault();
    window.location.href = window.location.href.replace("quote/confirm", "quote");
  }

  updateSubmitButtonState() {
    const serviceRadios = document.querySelectorAll(
      'input[name="form_object_deals_dashboard_quote[service_type]"]'
    );
    const paymentRadios = document.querySelectorAll(
      'input[name="form_object_deals_dashboard_quote[payment_method]"]'
    );

    const hasServiceRadios = serviceRadios.length > 1;

    const isServiceSelected = hasServiceRadios
      ? Array.from(serviceRadios).some((radio) => radio.checked)
      : true;
    const isPaymentSelected = Array.from(paymentRadios).some((radio) => radio.checked);

    this.submitButtonTarget.disabled = !(isServiceSelected && isPaymentSelected);
  }
}
