import { Controller } from "@hotwired/stimulus";
import collapsible from "@app/components/common/collapsible";
import collapsibleContainer from "@app/components/common/collapsibleContainer";

export default class extends Controller {

  connect() {
    collapsible();
    collapsibleContainer();
    if (this.data.get("openValue") == this.element.id) {
      this.openTaskCategory();
    }
  }

  openTaskCategory() {
    const collapseButton = this.element.querySelector(".bfg-collapse");
    collapseButton?.click();
  }
}
