import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.showModal("#modal-tribute_onboarding", "tribute_onboarding_modal_closed");
    this.showModal("#modal-tribute_admin");
  }

  showModal(modalSelector, storageKey = null) {
    const modal = document.querySelector(modalSelector);
    if (modal && (!storageKey || !localStorage.getItem(storageKey))) {
      window.addEventListener("load", () => {
        window.HSOverlay.open(modalSelector);
      });
    }
  }

  closeModal(event) {
    const modalSelector = event.currentTarget.dataset.modalSelector;
    const storageKey = event.currentTarget.dataset.storageKey;

    window.HSOverlay.close(modalSelector);
    if (storageKey) {
      localStorage.setItem(storageKey, "true");
    }
  }

}
