import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.sendPostMessageToIframe();
  }

  sendPostMessageToIframe() {
    const currentOrigin = window.location.origin;
    const allowedOrigins = [
      "https://dev-store.goodbyegoodboy.com.au",
      "https://store.goodbyegoodboy.com.au",
    ];

    window.addEventListener("message", (event) => {
      if (allowedOrigins.includes(event.origin) && event.data === "getHomeUrlFromApp") {
        event.source.postMessage(currentOrigin + this.element.dataset.url, event.origin);
      }
    });
  }
}
