const form = document.getElementById("new_pp_crem_quote");

const inputs = form.querySelectorAll("[type=radio]");

const target = form.querySelector(".pp_crem_quote_sequential-fields");

const submit = form.querySelector("[data-target=submit]");

const displayFields = () => {
  target.classList.remove("bfg-collapse");
  if (document.querySelector('[name="pp_crem_quote[relationship]"]:checked').value == "self") {
    document.querySelector(".pp_crem_quote_first_name label").innerText = "First Name";
    document.querySelector(".pp_crem_quote_email label").innerText = "Email";
    document.querySelector(".pp_crem_quote_postcode label").innerText = "Postcode";
    document.querySelector("#a-bit-more-information-header").innerText = "A bit about you";
  } else {
    document.querySelector(".pp_crem_quote_first_name label").innerText = "Your name";
    document.querySelector(".pp_crem_quote_email label").innerText = "Your email";
    document.querySelector(".pp_crem_quote_postcode label").innerText = "Their postcode";
    document.querySelector("#a-bit-more-information-header").innerText = "A bit about you and them";
  }
};

const enableSubmit = () => {
  submit.removeAttribute("disabled");
};

const handler = () => {
  displayFields();
  enableSubmit();
};

const listen = () => {
  inputs.forEach((input) => {
    input.addEventListener("change", handler);
  });
};

export default () => {
  listen();

  if (Array.from(inputs).some((input) => input.checked)) handler();

  document.querySelector("#pp_crem_quote_relationship_self").click();
};
