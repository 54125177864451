import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "tooltip", "facebook", "email"];

  connect() {
    this.facebookTarget.addEventListener("click", () => this.sendSegmentEvent("facebook"));
    this.emailTarget.addEventListener("click", () => this.sendSegmentEvent("email"));
  }

  highlightAndCopy() {
    this.inputTarget.select();
    this.copyToClipboard(this.inputTarget.value);
    this.sendSegmentEvent("link");
  }

  copyToClipboard(text) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        this.showTooltip();
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  }

  sendSegmentEvent(sharingMethod) {
    window.sendSegmentAnalytics("Nok", "Tributes Page", "share interaction by NOK", {
      sharing_method: sharingMethod,
    });
  }

  showTooltip() {
    const tooltip = this.tooltipTarget;
    tooltip.classList.remove("opacity-0", "invisible");
    tooltip.classList.add("opacity-100", "visible");

    setTimeout(() => {
      tooltip.classList.remove("opacity-100", "visible");
      tooltip.classList.add("opacity-0", "invisible");
    }, 2000);
  }
}
