import { Controller } from "@hotwired/stimulus";
import collapsibleContainer from "@app/components/common/collapsibleContainer";

export default class extends Controller {
  connect() {
    const statusComp = document.querySelector(".arrangement-status");
    if (!statusComp) {
      return;
    };
    const dashboardType = statusComp.classList[1];
    if (dashboardType === "deal") {
      collapsibleContainer(true);
    } else {
      collapsibleContainer();
    }
  }
}
