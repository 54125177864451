import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggle", "menu"];

  toggleMenu() {
    this.menuTarget.classList.toggle('over');
    this.toggleTarget.classList.toggle('active');
  }
}
