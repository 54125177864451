import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content", "funeralDetails", "guestbook", "memories"];
  static isInitialized = false;

  connect() {
    const urlParams = new URLSearchParams(window.location.search);
    const partialName = urlParams.get("partial");

    this.updateTabButtonState(partialName || this.element.dataset.defaultPartial);
    this.addListeners();
  }

  addListeners() {
    if (this.constructor.isInitialized) return;

    const targets = [];

    if (this.hasFuneralDetailsTarget) {
      targets.push({ target: this.funeralDetailsTarget, event: "funeral_details" });
    }

    if (this.hasMemoriesTarget) {
      targets.push({ target: this.memoriesTarget, event: "memories" });
    }

    if (this.hasGuestbookTarget) {
      targets.push({ target: this.guestbookTarget, event: "guestbook" });
    }

    targets.forEach(({ target, event }) => {
      target.addEventListener("click", () =>
        this.sendSegmentEvent(event, this.element.dataset.role)
      );
    });

    document.addEventListener("partial_navigation:loadPartial", this.handleLoadPartial.bind(this));

    this.constructor.isInitialized = true;
  }

  handleLoadPartial(event) {
    this.loadPartial(event.detail.partialName || this.element.dataset.defaultPartial);
  }

  switch(event) {
    const partialName = event.currentTarget.dataset.targetPartial;
    this.loadPartial(partialName);
    this.updateTabButtonState(partialName);
  }

  loadPartial(partialName) {
    const url = new URL(window.location.href);
    const turboFrame = this.element.querySelector("turbo-frame#refresh-tributes-partial");

    this.updateUrlParameter(partialName);

    url.searchParams.set("partial", partialName);
    turboFrame.src = url.toString();
  }

  updateTabButtonState(activePartial) {
    const buttons = Array.from(document.querySelectorAll(".partial-button"));
    const translateClasses = ["translate-x-0", "translate-x-full", "translate-x-[200%]"];

    buttons.forEach((button, index) => {
      const isActive = activePartial === button.dataset.targetPartial;
      button.classList.toggle("text-forest-500", isActive);
      button.classList.toggle("font-bold", isActive);
      button.classList.toggle("bg-forest-50", isActive);
      button.classList.toggle("text-gray-500", !isActive);
      button.classList.toggle("bg-chalk-200", !isActive);

      if (isActive) {
        this.updateSliderClasses(buttons, translateClasses[index]);
      }
    });
  }

  updateSliderClasses(buttons, translateClass) {
    const slider = document.querySelector(".slider");
    const sliderWidth = 100 / buttons.length;

    slider.classList.remove(
      ...Array.from(slider.classList).filter((cls) => cls.startsWith("translate-x-"))
    );
    slider.classList.add(translateClass);
    slider.style.width = `${sliderWidth}%`;
  }

  sendSegmentEvent(tab, role) {
    window.sendSegmentAnalytics("Nok", "Tributes Page", "viewed tab", {
      tab: tab,
      role: role,
    });
  }

  updateUrlParameter(partialName) {
    const url = new URL(window.location.href);
    url.searchParams.set("partial", partialName);
    window.history.pushState({}, "", url);
  }
}
