import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const closeButton = this.element.querySelector("#book_planning_call-close-button");

    if (closeButton) {
      closeButton.addEventListener("click", (event) => {
        event.preventDefault();
        window.location.reload();
      });
    }
  }

  reloadOnSubmitButton(event) {
    event.preventDefault();

    const form = event.target.closest("form");
    form.submit();

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
}
