import { Controller } from "@hotwired/stimulus";
import collapsibleContainer from "@app/components/common/collapsibleContainer";

const BREAKPOINT_TABLET = 992;

export default class extends Controller {
  connect() {
    // Collapse collapsable for mobile and tablet
    if (window.innerWidth < BREAKPOINT_TABLET) {
      collapsibleContainer();
    } else {
      collapsibleContainer(true);
    }
  }
}
