import { Controller } from "@hotwired/stimulus";

const MEDIUM_SCREEN = 992;
export default class extends Controller {
  static targets = ["toggle", "listItem", "listPrice", "listPriceWithoutCoffin"];

  connect() {
    this.setHeight("list-info");
    this.setHeight("list-service-details");
    this.setHeight("last-list-wrapper");
    this.setService();

    if (this.hasToggleTarget) {
      this.toggleTarget.addEventListener("change", (event) => {
        this.toggleService(event);
      });
    }
  }

  toggleService(event) {
    const isWithout = event.target.id === "without";
    this.togglePrice(isWithout);
    this.toggleCoffinItems(isWithout);
  }

  togglePrice(isWithout) {
    if (this.hasListPriceTarget) {
      this.listPriceTarget.classList.toggle("hidden", isWithout);
    }
    if (this.hasListPriceWithoutCoffinTarget) {
      this.listPriceWithoutCoffinTarget.classList.toggle("hidden", !isWithout);
    }
  }

  toggleCoffinItems(isWithout) {
    this.listItemTargets.forEach((item) => {
      let tooltip = item.querySelector(".tooltip__trigger");
      if (item.classList.contains("coffin-only")) {
        item.classList.toggle("disabled", isWithout);
        tooltip?.classList.toggle("disabled", isWithout);
      }
    });
  }

  setHeight(element) {
    if (window.innerWidth < MEDIUM_SCREEN) {
      return;
    }
    const headers = Array.from(document.getElementsByName(element));
    let maxHeight = Math.max(...headers.map((header) => header.offsetHeight));

    headers.forEach((header) => {
      header.style.height = `${maxHeight}px`;
    });
  }

  setService() {
    if (this.hasListPriceTarget) {
      if (this.hasListPriceWithoutCoffinTarget) {
        this.listPriceWithoutCoffinTarget.classList.toggle("hidden", true);
      }
    } else {
      this.togglePrice(true);
      this.toggleCoffinItems(true);
    }
  }
}
