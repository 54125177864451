import { Controller } from "@hotwired/stimulus";
import iconTrash from "../../../../assets/images/global/icons/icon-trash.svg";

export default class extends Controller {
  static targets = ["shortlistItem", "submitButton"];

  connect() {
    this.loadLocalStorageData();
    this.checkShortlist();
    this.getShortlistData();

    document.addEventListener("turbo:load", () => {
      this.checkShortlist();
    });
  }

  loadLocalStorageData() {
    // If there is stored data, parse it and load the items to the shortlist
    const storedShortlistData = localStorage.getItem("shortlistData");
    if (storedShortlistData) {
      const shortlistData = JSON.parse(storedShortlistData);
      shortlistData.forEach((itemData) => {
        this.addItem(itemData, true);

        // dispatch event for other controllers to listen to
        document.dispatchEvent(
          new CustomEvent("addedToShortlist", {
            detail: itemData.id,
          })
        );
      });
    }
  }

  getShortlistData() {
    document.addEventListener("addToShortlist", ({ detail: itemData }) => {
      this.addItem(itemData);
    });

    document.addEventListener("removeFromShortlist", ({ detail: itemData }) => {
      const itemIndex = this.shortlistItemTargets.findIndex(
        (item) => item.querySelector(".shortlist-item").id === itemData.id
      );

      this.removeItem({
        currentTarget: {
          dataset: {
            index: itemIndex + 1,
          },
        },
      });
    });
  }

  addItem(itemData, fromLocalStorage = false) {
    const emptySlot = this.shortlistItemTargets.find(
      (item) => !item.querySelector(".trashcan-button")
    );

    if (emptySlot) {
      const newItemHTML = `
        <div class="preference-index-label">${this.ordinalize(emptySlot.dataset.index)}</div>
        <div class="shortlist-item filled" id="${itemData.id}">
          <a href="${itemData.url}" class="image-container">
            <img src="${itemData.image}">
          </a>
          <a href="${itemData.url}" class="shortlist-content">
            <div class="tags">
              <span class="type">${itemData.type}</span>
            </div>
            <h4>${itemData.title}</h4>
            <div class="address">
              <span>${itemData.suburb}</span>
              <span>${itemData.postcode}</span>
            </div>
          </a>
          <button class="trashcan-button" style="display: ${
            this.hasSubmitButtonTarget ? "" : "none"
          }" id="${itemData.id}" data-index="${
        emptySlot.dataset.index
      }" data-action="click->shortlist#removeItem"><img src="${iconTrash}"></button>
        </div>`;

      emptySlot.innerHTML = newItemHTML;
      this.checkShortlist(fromLocalStorage);
    }
  }

  removeItem(event) {
    const itemIndex = parseInt(event.currentTarget.dataset.index, 10);
    const removeItemId = event.currentTarget.id;

    this.shortlistItemTargets[itemIndex - 1].innerHTML = "";

    for (let i = itemIndex; i < this.shortlistItemTargets.length; i++) {
      if (this.shortlistItemTargets[i].querySelector(".trashcan-button")) {
        this.shortlistItemTargets[i - 1].innerHTML = this.shortlistItemTargets[i].innerHTML;

        const newButton = this.shortlistItemTargets[i - 1].querySelector(".trashcan-button");
        const newLabel = this.shortlistItemTargets[i - 1].querySelector(".preference-index-label");
        if (newButton) {
          newButton.dataset.index = i;
          newLabel.innerText = this.ordinalize(i.toString());
          this.shortlistItemTargets[i].innerHTML = "";
        }
      }
    }

    for (let i = 0; i < this.shortlistItemTargets.length; i++) {
      if (!this.shortlistItemTargets[i].querySelector(".trashcan-button")) {
        this.clearItem(i + 1);
      }
    }

    this.checkShortlist();

    // dispatch event for other controllers to listen to
    document.dispatchEvent(
      new CustomEvent("removedFromShortlist", {
        detail: removeItemId,
      })
    );
  }

  clearItem(index) {
    const item = this.shortlistItemTargets[index - 1];
    item.dataset.index = index;
    item.innerHTML = `
      <div class="shortlist-item empty">
        <span class="preference-label">Venue Preference ${index}</span> 
        <span>${index > 1 ? "(optional)" : ""}</span>
      </div>`;
  }

  checkShortlist(fromLocalStorage) {
    const filledItems = this.shortlistItemTargets.filter((item) =>
      item.querySelector(".trashcan-button")
    );

    if (filledItems.length === 3) {
      document.dispatchEvent(new CustomEvent("shortlistFull"));
    } else {
      document.dispatchEvent(new CustomEvent("shortlistNotFull"));
    }

    if (filledItems.length === 0 && this.hasSubmitButtonTarget) {
      this.submitButtonTarget.classList.add("disabled");
    } else if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.classList.remove("disabled");
    }

    // If we came from localStorage, we don't want to save the data again.
    if (!fromLocalStorage) {
      const shortlistData = filledItems.map((item) => {
        const id = item.querySelector(".shortlist-item").id;
        const url = item.querySelector(".shortlist-content").href;
        const image = item.querySelector("img").src;
        const title = item.querySelector("h4").textContent;
        const type = item.querySelector(".type").textContent;
        const suburb = item.querySelectorAll(".address span")[0].textContent;
        const postcode = item.querySelectorAll(".address span")[1].textContent;
        return { id, url, image, title, suburb, type, postcode };
      });

      // Write shortlist data to localStorage
      localStorage.setItem("shortlistData", JSON.stringify(shortlistData));
    }
  }

  ordinalize(number) {
    switch (number) {
      case "1":
        return "1st";
      case "2":
        return "2nd";
      case "3":
        return "3rd";
      default:
        return "";
    }
  }
}
