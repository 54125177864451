import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["add", "body"];

  addBody() {
    let content = this.bodyTarget.value;
    this.addTarget.insertAdjacentHTML('beforebegin', "<li>" + content + "</li>");
  }
}
