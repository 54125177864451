import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
  connect() {
    this.noKeyboardMobile();

    const withTime = this.data.get("with-time") == "true";
    const withWeekends = this.data.get("with-weekends") == "true";
    const withMinDate = this.data.get("with-min-date") == "true";

    this.initFlatpickr(withTime, withWeekends, withMinDate, navigator.userAgent);
  }

  noKeyboardMobile() {
    document.querySelectorAll(".datepicker").forEach((element) => {
      element.setAttribute("autocomplete", "off");
      element.addEventListener("focus", function () {
        this.blur();
      });
    });
  }

  setRequired() {
    const firstDatepickerElement = document.querySelector(".datepicker");
    firstDatepickerElement.setAttribute("required", "true");
  }

  initFlatpickr(withTime, withWeekends, withMinDate, useragent) {
    const currentDate = new Date();

    if (withMinDate) {
      currentDate.setDate(currentDate.getDate() + 7);
    }

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(useragent)) {
      flatpickr(".datepicker", {
        enableTime: withTime,
      });
    } else {
      flatpickr(".datepicker", {
        dateFormat: withTime ? "d-m-Y H:i" : "d-m-Y",
        allowInput: true,
        minDate: currentDate,
        enableTime: withTime,
        enable: [
          function (date) {
            return withWeekends || date.getDay() % 6 != 0; // disable weekends
          },
        ],
      });
    }
  }
}
