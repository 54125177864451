import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.setVenuestoForm();
  }

  setVenuestoForm() {
    var venue_1 = document.getElementById("memorial_selection_venue_1");
    var venue_2 = document.getElementById("memorial_selection_venue_2");
    var venue_3 = document.getElementById("memorial_selection_venue_3");
    var venues = [venue_1, venue_2, venue_3];

    // If there is stored data, parse it and load the items to the shortlist
    const storedShortlistData = localStorage.getItem("shortlistData");
    if (storedShortlistData) {
      const shortlistData = JSON.parse(storedShortlistData);
      let i = 0;
      shortlistData.forEach((itemData) => {
        venues[i].value = itemData.id;
        i += 1;
      });
    }
  }
}
