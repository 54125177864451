import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["loadButton"];

  connect() {
    const allmembers = document.querySelectorAll(".team-member-container");

    this.membersArray = Array.prototype.slice.call(allmembers);
    this.currentMemberIndex = this._getColumns() * 2;
    this._initializeMembers(this.membersArray);
    if (this.membersArray.length <= this.currentMemberIndex) {
      this._hideloadButton();
    }
  }

  _hideloadButton() {
    this.loadButtonTarget.classList.add("hidden")
  }

  loadMoreMembers() {
    this.membersArray.forEach((member) => {
        member.style.display = "flex";
    });
    this.loadButtonTarget.innerText = this.loadButtonTarget.innerText.replace("More", "Less");
    this.loadButtonTarget.dataset.action = "click->team#loadLessMembers";
  }

  loadLessMembers() {
    this._initializeMembers(this.membersArray);
    this.loadButtonTarget.innerText = this.loadButtonTarget.innerText.replace("Less", "More");
    this.loadButtonTarget.dataset.action = "click->team#loadMoreMembers";
  }

  _getColumns() {
    this.membersContainer = document.querySelector(".team-body");
    const columnString = getComputedStyle(this.membersContainer).gridTemplateColumns;
    return columnString.split(" ").length;
  }

  _initializeMembers(membersArray) {
    membersArray.forEach((member, index) => {
      if (index >= this.currentMemberIndex) {
        member.style.display = "none";
      }
    });
  }
}
