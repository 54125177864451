import { Controller } from "@hotwired/stimulus"

const pollData = (document_obj) => {
  const headers = { 'Accept': 'text/html' };
  const objectId = document_obj.getAttribute('data-object-id');
  const container = document.querySelector(`#document-list-container-${objectId}`);
  var intervalId = setInterval(() => {
    fetch(container.getAttribute('data-url'), { headers: headers })
      .then(response => response.text())
      .then(body => {
        container.innerHTML = body;
        const doc_obj = container.querySelector("div.document-processing");
        if (doc_obj === null) {
          clearInterval(intervalId);
        }
      })
  }, 10000);
};

export default class extends Controller {
  connect() {
    const documents = document.querySelectorAll("div.document-processing");
    if (documents.length > 0) {
      documents.forEach(document_obj => {
        pollData(document_obj);
      });
    }
  }
}
