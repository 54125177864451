import { Controller } from "@hotwired/stimulus";
import collapsibleContainer from "@app/components/common/collapsibleContainer";

export default class extends Controller {
  static collapsibleInitialized = false;

  connect() {
    if (!this.constructor.collapsibleInitialized) {
      collapsibleContainer();
      this.constructor.collapsibleInitialized = true;
      this.handleHashNavigation();
    }
  }

  handleHashNavigation() {
    const hash = window.location.hash.replace("#", "");
    if (hash) {
      this.scrollToElement(hash);
    }
  }

  scrollToElement(hash) {
    const targetElement = document.getElementById(hash);
    if (targetElement) {
      const collapseButton = targetElement.querySelector(".bfg-collapse");
      collapseButton?.click();
      setTimeout(() => targetElement.scrollIntoView({ behavior: "smooth", block: "end" }), 300);
    }
  }
}
