import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["left", "right", "content"];

  scrollLeft() {
    this.contentTarget.scrollBy(-246, 0);
  }

  scrollRight() {
    this.contentTarget.scrollBy(246, 0);
  }
}