import email from "@app/components/common/email";
import collapsible from "@app/components/common/collapsible";
import collapsibleContainer from "@app/components/common/collapsibleContainer";
import Rails from "@rails/ujs";

function handleSign(e) {
  e.preventDefault();
  const signatureBox = document.querySelector(".summary-sign-box");
  const url = signatureBox.getAttribute("data-url");
  console.log("signing", url);
  Rails.ajax({
    url: url,
    type: "PUT",
    dataType: "json",
  });

  if (signatureBox.classList.contains("signed")) {
    return;
  }

  signatureBox.className += "signed";
  signatureBox.innerHTML = signatureBox.getAttribute("data-name");
  document.querySelector(".quote-form__submit").disabled = false;
}

export default () => {
  document.querySelector(".summary-sign-box").addEventListener("click", handleSign);
  email();
  collapsible();
  collapsibleContainer();
};
