import { partial } from "@lib/fn";

const changeSelection = (selector, plan) => {
  const currentPlan = selector.querySelector(".selected");

  if (currentPlan == plan) return;

  currentPlan?.classList?.remove("selected");

  plan.classList.add("selected");
};

const enableSubmit = (form, event) => {
  form.querySelector("#plan").value = event.detail.period;
  form.querySelector("#type").value = event.detail.type;
  form.querySelector("button").classList.remove("disabled");
};

const selectPlan = (selector, plan) => {
  changeSelection(selector, plan);

  selector.dispatchEvent(new CustomEvent("plan:selected", { detail: { period: plan.dataset.period, type: plan.dataset.type }}));
};

export default () => {
  const selector = document.querySelector("[data-action=\"select-plan\"]");
  const form = document.querySelector("#quote_plan_form");

  selector.querySelectorAll("[data-period]").forEach(plan => {
    plan.addEventListener("click", partial(selectPlan, selector, plan));
  });

  selector.addEventListener("plan:selected", partial(enableSubmit, form));
};
