import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["short", "full", "button"];

  connect() {
    this.adjustShortHeight();
    window.addEventListener("resize", this.adjustShortHeight.bind(this));
  }

  adjustShortHeight() {
    if (this.hasShortTarget) {
      this.shortTarget.classList.remove("hidden");
      const shortParagraph = this.shortTarget.querySelector("p");

      shortParagraph.style.height = "auto";
      const shortHeight = shortParagraph.clientHeight;
      // const lineHeight = parseInt(window.getComputedStyle(shortParagraph).lineHeight);

      shortParagraph.style.height = `${shortHeight}px`;
    }
  }

  toggle() {
    this.shortTarget.classList.toggle("hidden");
    this.fullTarget.classList.toggle("hidden");
    
    if (this.shortTarget.classList.contains("hidden")) {
      this.buttonTarget.innerText = "Read Less";
    } else {
      this.buttonTarget.innerText = "Read More";
    }
  }  
}
